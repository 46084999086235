import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="navbar"
export default class extends Controller {
  static targets = ["logo", "mobile", "desktop"]
  connect() {
  }

  updateNavbar() {
    if (window.scrollY >= window.innerHeight) {
      this.element.classList.add("navbar-black")
      this.logoTarget.classList.add("width-unset")
      //this.mobileTarget.classList.remove("d-none")
      //this.desktopTarget.classList.add("d-none")
    } else {
      this.element.classList.remove("navbar-black")
      this.logoTarget.classList.remove("width-unset")
      //this.mobileTarget.classList.add("d-none")
      //this.desktopTarget.classList.remove("d-none")
    }
  }

}
