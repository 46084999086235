// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "bootstrap"

document.addEventListener("DOMContentLoaded", function() {
  var cookieBanner = document.getElementById("cookie-banner");
  console.log("ciao");
  if (!Cookies.get("cookie_consent")) {
    cookieBanner.style.display = "block";
  }

  var acceptButton = document.getElementById("cookie-accept");

  acceptButton.addEventListener("click", function() {
    Cookies.set("cookie_consent", true, { expires: 365 });
    cookieBanner.style.display = "none";
  });
});
